import { lazy } from "react";
import axios from "axios";
import moment from "moment-timezone";
import { ROOT } from "./constants";

export const APP_VERSION = "1.0";

export function retry(fn, retriesLeft = 5, interval = 500) {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            reject(error);
            return;
          }

          // Passing on "reject" is the important part
          retry(fn, interval, retriesLeft - 1).then(resolve, reject);
        }, interval);
      });
  });
}

export function load(component, settings = {}) {
  return lazy(() =>
    retry(() => {
      if (typeof settings.auth !== "undefined") {
        const auth = settings.auth();
        if (
          auth &&
          auth.status === false &&
          typeof auth.redirect !== "undefined"
        )
          return import(`./components/${auth.redirect}`);
      }
      return import(`./components/${component}`);
    })
  );
}

export function fromNow(dateTime) {
  return moment.utc(dateTime).fromNow();
}

export function utcToLocal(dateTime, format = "DD-MM-YYYY") {
  return moment.utc(dateTime).local().format(format);
}
export function taxdetails() {
  return alert("tax called");
}
export function clearSession(redirectTo) {
  localStorage.removeItem("user");
  sessionStorage.removeItem("user");
  sessionStorage.removeItem('utmCampaign');
  sessionStorage.removeItem('utmSource');
  sessionStorage.removeItem('currency');
  sessionStorage.removeItem('webViewClass');
  localStorage.removeItem('currency');
  localStorage.removeItem('isDemoUser');
  localStorage.removeItem('show_master_class_modal');
  localStorage.removeItem('location');

  // Update auth state
  // dispatch({
  //   type: 'SAVE_AUTH',
  //   payload: false
  // });
  // redirect
  if (typeof redirectTo !== "undefined") window.location = redirectTo;
}

export function verifyAndUpdateAppVersion() {
  // Display App Version
  console.log("APP VERSION", APP_VERSION);

  const version = localStorage.getItem("APP_VERSION");
  if (version === null || version !== APP_VERSION) {
    localStorage.setItem("APP_VERSION", APP_VERSION);
    clearSession();
  }
}

export function isMobile(callback) {
  const mobileWidth = 1024;

  // On window resize event
  if (typeof callback !== "undefined") {
    window.addEventListener("resize", () => {
      callback(window.outerWidth <= mobileWidth);
    });
  }

  return window.outerWidth <= mobileWidth;
}

export function loadScript(
  callback,
  scriptId,
  jsSrc,
  callbackParams = null,
  attrs = {}
) {
  const existingScript = document.getElementById(scriptId);

  if (!existingScript) {
    const script = document.createElement("script");
    script.src = jsSrc;
    script.id = scriptId;
    if (Object.keys(attrs).length > 0) {
      Object.keys(attrs).forEach((key) => {
        script.setAttribute(key, attrs[key]);
      });
    }
    document.body.appendChild(script);

    script.onload = () => {
      if (callback) callback(callbackParams);
    };
  }

  if (existingScript && callback) callback(callbackParams);
}

export function unLoadScript(scriptId) {
  const existingScript = document.getElementById(scriptId);

  if (existingScript) {
    window.$$(existingScript).remove();
  }
}

export function _dispatch(nextState, rerender = false, compName = null) {
  rerender = rerender ? new Date().getTime() : nextState.status;
  return {
    ...nextState,
    status: false,
    compName,
  };
}

export function jsUcfirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function currencyFormat(amount, currency, decimal,showCurrencySymbol = true, userCurrency, classId) {
  const decimalValue = decimal ? decimal : 0;
  let defaultCurrency = { value: "inr", label: "INR", icon: "₹", rate: 1 }
  let currentCurrency = defaultCurrency;
  const currencyData = getSessionItem("currency");
  if(userCurrency){
    currentCurrency = userCurrency;
  } else if(currencyData) {
    currentCurrency = currencyData;
  }

  if(classId === '660584b8b2966a00124a10ea'){
    amount = parseFloat(amount) > 0 || parseFloat(amount) < 0 ? parseInt(amount * currentCurrency.rate) : 0;
  } else amount = parseFloat(amount) > 0 || parseFloat(amount) < 0 ? parseFloat(amount * currentCurrency.rate).toFixed(decimalValue) : 0;
  
  if (isNaN(amount)) return "";

  let convertedAmount = amount;
  if(currentCurrency.value && currentCurrency.value.toLowerCase() === 'inr'){
    convertedAmount = Number(amount) > 9999 ?  indiaCurrencyFormat(Number(amount)) : amount;
  }
  return showCurrencySymbol ? `${currentCurrency.icon} ${convertedAmount}` : convertedAmount;
}

export function dateFormatBooking(dateTime, format) {
  let currentTimeZone = getSessionItem("user").timezone.offsetInMinutes;
  let localTimeZone = -(new Date().getTimezoneOffset());
  let diffTimeZone = localTimeZone - currentTimeZone;
  let newDate = new Date(dateTime)
  let formattedDate = moment(newDate.getTime() - (diffTimeZone * 60 * 1000)).format(format)

  return formattedDate;
}
export function dateFormat(dateTime, format) {
  return moment(dateTime).format(format);
}
export function expandSidebar(value) {
  if(!isMobile()) {
    if(!value && !document.body.classList.contains("fullContent")) {
      window.$$('#opacityLayer').removeClass('open');
      window.$$('body').addClass('fullContent');
    }
  }
}


export function dateFormatTimeZone(dateTime, format) {
  let currentTimeZone = getSessionItem("user").timezone.offsetInMinutes;
  let localTimeZone = -(new Date().getTimezoneOffset());
  let diffTimeZone = localTimeZone - currentTimeZone; 
  dateTime = new Date(new Date(dateTime).getTime() - diffTimeZone * 60 * 1000);
  return moment(dateTime).format(format);
}

export function adjustDateFromTimeZoneToLocal(dateTime) {
  let currentTimeZone = getSessionItem("user").timezone.offsetInMinutes;
  let localTimeZone = -(new Date().getTimezoneOffset());
  let diffTimeZone = localTimeZone - currentTimeZone;
  dateTime = new Date(dateTime.getTime() - (diffTimeZone * 60 * 1000));
  return dateTime;
}

export function adjustTimeZoneToCurrentTimeZone(avail, teacherTimeZone) {
  let studentTimeZone = getSessionItem("user").timezone;
  let diffTimeZone =
    studentTimeZone.offsetInMinutes - teacherTimeZone.offsetInMinutes;
  avail.forEach((time) => {
    let dayFrom = new Date(time.from);
    let dayTo = new Date(time.to);
    time.from = new Date(Date.parse(dayFrom) + diffTimeZone * 60 * 1000);
    time.to = new Date(Date.parse(dayTo) + diffTimeZone * 60 * 1000);
  });
  return avail;
}

export function adjustTimeFrom0(time){
  let currentTimeZone = getSessionItem("user").timezone.offsetInMinutes;
  let dayFrom = new Date(time.from);
  let dayTo = new Date(time.to);
  time.from = new Date(Date.parse(dayFrom) + currentTimeZone * 60 * 1000);
  time.to = new Date(Date.parse(dayTo) + currentTimeZone * 60 * 1000);
  return time;
}

export async function adjustTimeZoneFrom0ToCurrentTimeZone(arrTime) {
  let currentTimeZone = getSessionItem("user").timezone.offsetInMinutes;
  arrTime.forEach((time) => {
    let dayFrom = new Date(time.from);
    let dayTo = new Date(time.to);
    time.from = new Date(Date.parse(dayFrom) + (currentTimeZone) * 60 * 1000);
    time.to = new Date(Date.parse(dayTo) + (currentTimeZone) * 60 * 1000);
  });
  return arrTime;
}

export function adjustTimeZoneFromLocalToCurrentTimeZone(arrTime) {
  let currentTimeZone = getSessionItem("user").timezone.offsetInMinutes;
  let localTimeZone = -(new Date().getTimezoneOffset());
  let diffTimeZone = localTimeZone - currentTimeZone;
  arrTime.forEach((time) => {
    let dayFrom = new Date(time.from);
    let dayTo = new Date(time.to);
    time.from = new Date(dayFrom.getTime() + (diffTimeZone * 60 * 1000));
    time.to = new Date(dayTo.getTime() + (diffTimeZone * 60 * 1000));
  });
  console.log(arrTime)

  return arrTime;
}
export function adjustTimeZoneFromTimeZoneToLocal(arrTime) {
  let currentTimeZone = getSessionItem("user").timezone.offsetInMinutes;
  let localTimeZone = -(new Date().getTimezoneOffset());
  let diffTimeZone = localTimeZone - currentTimeZone;
  arrTime.forEach((time) => {
    let dayFrom = new Date(time.from);
    let dayTo = new Date(time.to);
    time.from = new Date(dayFrom.getTime() - (diffTimeZone * 60 * 1000));
    time.to = new Date(dayTo.getTime() - (diffTimeZone * 60 * 1000));
  });
  console.log(arrTime)

  return arrTime;
}

export function adjustTimeZoneTo0(arrTime) {
  let currentTimeZone = getSessionItem("user").timezone.offsetInMinutes;
  arrTime.forEach((time) => {
    let dayFrom = new Date(time.from);
    let dayTo = new Date(time.to);
    time.from = new Date(Date.parse(dayFrom) - currentTimeZone * 60 * 1000);
    time.to = new Date(Date.parse(dayTo) - currentTimeZone * 60 * 1000);
  });
  return arrTime;
}

export function dateFormatToTime(dateTime) {
  return moment(dateTime).calendar(null, {
    sameElse: "DD/MM/YYYY [at] LT",
  });
}

export function getCurrentTimeByDate(date) {
  let currentTimeZone = getSessionItem("user").timezone.offsetInMinutes;
  return new Date(Date.parse(date) + currentTimeZone * 60 * 1000);
}

export function getCurrentTimeByTime(time) {
  let currentTimeZone = getSessionItem("user").timezone.offsetInMinutes;
  return new Date(time + currentTimeZone * 60 * 1000).getTime();
}

export function setSessionItem(itemName, itemValue) {
  //alert("called session");
  if (typeof itemValue === "object") {
    itemValue = JSON.stringify(itemValue);
  }
  localStorage.setItem(itemName, itemValue);
}

export function setMemoryItem(itemName, itemValue) {
  if (typeof itemValue === "object") {
    itemValue = JSON.stringify(itemValue);
  }
  sessionStorage.setItem(itemName, itemValue);
}

export function getSessionItem(itemName) {
  let result = null;
  const item = sessionStorage.getItem(itemName)
    ? sessionStorage.getItem(itemName)
    : localStorage.getItem(itemName);
  try {
    result = JSON.parse(item);
  } catch (e) {
    result = item;
  }

  return result;
}

export function getLocationItem(itemName) {
  let result = null;
  const item = localStorage.getItem(itemName);
  try {
    result = JSON.parse(item);
  } catch (e) {
    result = item;
  }

  return result;
}

export function removeSessionItem(itemName) {
  const item = localStorage.removeItem(itemName);

  return item;
}

export function removeMemoryItem(itemName) {
  const item = sessionStorage.removeItem(itemName);

  return item;
}

export function isAuth() {
  const user = getSessionItem("user");
  // alert(typeof(user));

  return user && typeof user.token !== "undefined" ? user : false;
}

export function _axios(config, $this) {
  // Stop execution of redundant requests
  if ($this.state && $this.state.processing) {
    return new Promise((resolve, reject) => {});
  }
  // For dummy requests
  if ($this.state && $this.state.dummy) {
    return new Promise((resolve, reject) => {
      resolve($this.state.dummy);
    });
  }
  window.axiosThis = $this;

  return axios(config);
}

export function timeZoneFormat(timezones) {
  var offset = 0;
  var z = 0;
  timezones.forEach((obj, index) => {
    offset = obj.offsetInMinutes;
    z = `${offset < 0 ? "-" : "+"}${pad(
      parseInt(Math.abs(offset / 60)),
      2
    )}:${pad(Math.abs(offset % 60), 2)}`;
    obj.utcTimeForm = obj.text + " (UTC " + z + ")";
  });
  return timezones;
}

export function pad(s, size) {
  s = s.toString();
  while (s.length < (size || 2)) {
    s = "0" + s;
  }
  return s;
}

export function isApp($this) {
  return $this.props.history && $this.props.history.location.search === "?app=1";
}

// Internet speed
export function measureConnectionSpeed(error, success) {
  var startTime, endTime;
  var download = new Image();
  var imageAddr = ROOT + "/assets/images/Logo-whoat-02.png";
  var downloadSize = 4995374; //bytes
  download.onload = function () {
    endTime = new Date().getTime();
    showResults();
  };

  download.onerror = function (err, msg) {
    error("Connection failed. Try again.");
  };

  startTime = new Date().getTime();
  var cacheBuster = "?nnn=" + startTime;
  download.src = imageAddr + cacheBuster;

  function showResults() {
    var duration = (endTime - startTime) / 1000;
    var bitsLoaded = downloadSize * 8;
    var speedBps = (bitsLoaded / duration).toFixed(2);
    var speedKbps = (speedBps / 1024).toFixed(2);
    var speedMbps = (speedKbps / 1024).toFixed(2);
    success(
      (speedMbps < 2 ? "Poor" : "Excellent") +
        " Connection · " +
        Math.round(speedMbps) +
        "Mbps"
    );
  }
}

export function getDayOfWeek(numDays) {
  const days = ["", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  return days
    .filter((d, index) => {
      return numDays.indexOf(index) > -1;
    })
    .join(", ");
}

export function validateEmail(email) {
  let regexEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/;
  if (email.match(regexEmail)) {
    return true;
  } else {
    return false;
  }
};

export function validatePassword(password) {
  let regexPassword = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{6,}$/;
  if (password.match(regexPassword)) {
    return true;
  } else {
    return false;
  }
}

export function validateNumber(inputNumber) {
  let regexNumber = /^[0-9\b]+$/;
  if (inputNumber.match(regexNumber)) {
    return true;
  } else {
    return false;
  }
};

export function validURL(str) {
  var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  return !!pattern.test(str);
}

export const currentDateToUtcDate = (date) => {
  let utcDate = '';
  if (date) {
    utcDate = moment(date, `YYYY-MM-DD HH:mm:ss`)
      .utc()
      .format();
  }
  return utcDate;
};

export const utcToUserTimezone = (timezone: string, date: string, toDateFormat: string, currentDateFormat: string) => {
  if(!timezone){
    const user = isAuth();
    if(user && user.timezone)
      timezone = user.timezone.value;
  }
  let utcDate = '';
  if (date && timezone) {
    if(currentDateFormat === undefined)
      utcDate = moment.utc(date).tz(timezone).format(toDateFormat);
    else
      utcDate = moment.utc(date, currentDateFormat).tz(timezone).format(toDateFormat); 
  }
  return utcDate;
};

export const indiaCurrencyFormat = (inputAmount) => {
  let x=inputAmount;
  x=x.toString();
  let afterPoint = '';
  if(x.indexOf('.') > 0)
    afterPoint = x.substring(x.indexOf('.'),x.length);
  x = Math.floor(x);
  x=x.toString();
  let lastThree = x.substring(x.length-3);
  let otherNumbers = x.substring(0,x.length-3);
  if(otherNumbers !== '')
    lastThree = ',' + lastThree;
  const convertedAmt = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree + afterPoint;
  return convertedAmt;
};

export const getDaysDiff = (fromDate, toDate) => {

  let daysDiff = moment(toDate).diff(moment(fromDate), 'years');

  return daysDiff;
};

export const getCourseCompletionYearList = () => {
  // Year Of Completion
  const completionYearsList = [];
  let toYear = [];
  const yearToStart = new Date().getFullYear() - 100;
  const numberOfCompletionYears = new Date().getFullYear() - yearToStart;
  for (var i = 0; i <= numberOfCompletionYears; i++) {
    toYear[i] = new Date().getFullYear() - i;
    completionYearsList.push({
      label: toYear[i],
      value: toYear[i]
    })
  }

  return completionYearsList;
};

export const getMonthList = () => {
  const monthList = [
    { label: 'January', value: '1'},
    { label: 'February', value: '2'},
    { label: 'March', value: '3'},
    { label: 'April', value: '4'},
    { label: 'May', value: '5'},
    { label: 'June', value: '6'},
    { label: 'July', value: '7'},
    { label: 'August', value: '8'},
    { label: 'September', value: '9'},
    { label: 'October', value: '10'},
    { label: 'November', value: '11'},
    { label: 'December', value: '12'}
  ];

  return monthList;
};

export const patchMonthData = (selectedItemLabel) => {
  const monthList = [
    { label: 'January', value: '1'},
    { label: 'February', value: '2'},
    { label: 'March', value: '3'},
    { label: 'April', value: '4'},
    { label: 'May', value: '5'},
    { label: 'June', value: '6'},
    { label: 'July', value: '7'},
    { label: 'August', value: '8'},
    { label: 'September', value: '9'},
    { label: 'October', value: '10'},
    { label: 'November', value: '11'},
    { label: 'December', value: '12'}
  ];

  const filterData = monthList.filter(item => item.label === selectedItemLabel);
  return filterData.length > 0 && filterData[0] ? filterData[0] : '';
};

export const getRandomPastelColor = () => {
  return "hsl(" + 360 * Math.random() + ',' +
    (25 + 70 * Math.random()) + '%,' +
    (85 + 10 * Math.random()) + '%)'
};

export const isHTMLString = (str) => {
  return /<[a-z][\s\S]*>/i.test(str);
};

export const removeHtmlTags = (htmlString) => {
  return htmlString.replace(/<[^>]*>/g, '');
};