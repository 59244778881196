import React, { Component } from 'react';

export default class App extends Component {
  constructor(props){
    super(props);
    this.state = {
      id: Date.now(),
      elements: [],
      renderElement: null,
      override: true,
      status: Date.now()
    };

    this.setStates = this.setStates.bind(this);
  }

  setStates(state){
    this.setState({
      ...this.state,
      ...state,
      status: Date.now()
    });
  }

  remove(id){
    const {elements} = this.state;
    this.setState({
      elements: elements.filter(elem => elem._id !== id)
    });
    window.$$('.modal-backdrop:eq(0)').remove();
  }

  removeAll(){
    this.setState({
      elements: []
    });
    window.$$('.modal-backdrop').remove();
  }

  componentDidUpdate(prevProps, prevState, snapshot){
    let elements = [];
    if(!this.state.elements.length){
      window.$$('body').removeClass('modal-open');
      window.$$('.modal-backdrop').remove();
    }else{
      window.$$('body').addClass('modal-open');
      //window.$$('.modal-backdrop:gt(0)').remove();
    }

    if(window.$$('.modal-backdrop').length > 1){
      window.$$('.modal-backdrop').not('.modal-backdrop:last').remove();
    }

    if(prevState.status === this.state.status)
      return;

    if(!this.state.override){
      elements = [...this.state.elements, {_id: this.state.id, component: this.state.renderElement}];
    }else{
      elements = [{_id: this.state.id, component: this.state.renderElement}];
    }

    this.setState({
      elements
    });
  }

  render() {
    const {elements} = this.state;

    return (
      <>
        {
          elements.map(elem => {
            return <div key={elem._id}>{elem.component}</div>
          })
        }
        {window.setFooter(this)}
      </>
    );
  }
}
