import { load } from './utilities';

export const ROUTES = {
  WRAPPER: { 
    path: '/',
    exact: false,
    component: load('Wrapper')
  },

  HOME: { 
    path: '/',
    exact: false,
    component: load('HomeNew')
  },

  LANDING: { 
    path: '/',
    exact: true,
    component: load('Landing')
  },

  LOGOUT: { 
    path: '/logout',
    exact: true,
    component: load('Logout')
  },

  VERIFYEMAIL: { 
    path: '/verify-email/:email?',
    exact: true,
    component: load('VerifyTeacherEmail')
  },

  VERIFYEMAILTOKEN: { 
    path: '/verify-email-token',
    exact: true,
    component: load('VerifyTeacherEmailToken')
  },

  VERIFYEMAILBYMAIL: { 
    path: '/emailVerify/:token',
    exact: true,
    component: load('user/EmailVerified')
  },

  RESETPASSWORD: { 
    path: '/user/password/change/:resetToken/:type?',
    exact: false,
    component: load('user/ResetPassword')
  },

  DASHBOARD: { 
    path: '/dashboard',
    exact: false,
    component: load('Dashboard')
  },

  TEACHERDASHBOARDINDEX: { 
    path: '/dashboard',
    exact: false,
    component: load('teacher/Index')
  },

  TEACHERDASHBOARD: { 
    path: '/dashboard',
    exact: true,
    component: load('teacher/DashboardIndex')
  },

  TEACHERSTATSDETAIL: { 
    path: '/dashboard/stats',
    exact: true,
    component: load('teacher/StatsDetail')
  },

  CHOOSEINTERESTS: { 
    path: '/setup-profile/choose-interests',
    exact: true,
    component: load('student/ChooseInterests')
  },

  PROFILE: { 
    path: '/dashboard/complete-profile',
    exact: true,
    component: load('Profile')
  },
  SAVEDCLASSES: {
    path: '/savedclasses',
    exact: true,
    component: load('SavedClasses')
  },
  SAVEDCLASSESDETAILS: {
    path: '/savedclasses/list',
    exact: true,
    component: load('SavedClassesDetails')
  },
  FOLLOWERS: {
    path: '/followers',
    exact: true,
    component: load('Followers')
  },
  FOLLOWING: {
    path: '/following',
    exact: true,
    component: load('Following')
  },
  SETUPPROFILE: { 
    path: '/dashboard/setup-profile',
    exact: true,
    component: load('SetupTeacherProfile')
  },
  SETUPPROFILEPREVIEW: { 
    path: '/dashboard/profile-preview',
    exact: true,
    component: load('SetupTeacherProfilePreview')
  },

  TEACHERCLASSES: { 
    path: '/dashboard/myclasses',
    exact: true,
    component: load('teacher/MyClasses')
  },

  CREATE_CLASS: { 
    path: '/teacher/create-class/:type',
    exact: false,
    component: load('teacher/ClassDetails')
  },
  
  EDIT_CLASS: { 
    path: '/teacher/edit-class/:id',
    exact: false,
    component: load('teacher/ClassDetails')
  },

  TEACHERBOOKINGS: { 
    path: '/dashboard/mybookings',
    exact: true,
    component: load('teacher/MyBookings')
  },

  STUDENTBOOKINGS: { 
    path: '/mybookings',
    exact: true,
    component: load('student/MyBookings')
  },

  STUDENTLIBRARY: { 
    path: '/mylibrary',
    exact: true,
    component: load('student/MyLibrary')
  },

  STUDENTBOOKINGDETAIL: { 
    path: '/mybookings/:id',
    exact: true,
    component: load('student/BookingDetail')
  },

  TEACHERBOOKINGDETAIL: { 
    path: '/dashboard/mybookings/:id',
    exact: true,
    component: load('teacher/BookingDetail')
  },

  TEACHERPROFILE: { 
    path: '/teacher/profile/:id',
    exact: true,
    component: load('teacher/ViewTeacherDetails')
  },
  VIEW_TEACHER_DETAILS: {
    path: '/teacher/:id',
    exact: true,
    component: load('teacher/ViewTeacherDetails')
  },

  JOINMEETING: { 
    path: '/join-meeting/:id/:token',
    exact: true,
    component: load('JoinMeeting')
  },

  VERIFYMEETING: { 
    path: '/verify-meeting/:id',
    exact: true,
    component: load('VerifyMeetingScreen')
  },

  WALLET: { 
    path: '/wallet',
    exact: true,
    component: load('Wallet')
  },
  VIEW_CLASS_DETAILS: {
    path: '/:type/course/:id',
    exact: true,
    component: load('teacher/ViewClassDetails')
  },
  VIEW_COURSE: {
    path: '/courses/:url',
    exact: true,
    component: load('teacher/ViewClassDetails')
  },
  SCHEDULE_CLASSES_DETAILS: { 
    path: '/classes/scheduleClassDetails/:id/:status?/:utm_source?/:utm_campaign?',
    exact: true,
    component: load('teacher/ScheduleClassDetails')
  },
  MASTERING_CLASSES_DETAILS: {
    path: '/mastering-applied-ai',
    exact: true,
    component: load('teacher/ViewClassDetails')
  },

  SCHEDULE_CLASSES_BOOKING: {
    path: '/classes/scheduleClassBooking',
    exact: true,
    component: load('student/ScheduleClassBooking')
  },
  GROUP_CLASSES_BOOKING: {
    path: '/classes/groupClassBooking',
    exact: true,
    component: load('student/GroupClassBooking')
  },
  
  MESSAGES: { 
    path: '/messages',
    exact: true,
    component: load('chat/Messages')
  },

  MESSAGES_SINGLE: { 
    path: '/messages/:conversationId',
    exact: true,
    component: load('chat/Messages')
  },

  NOTFOUND: { 
    path: '/page-not-found',
    exact: true,
    component: load('PageNotFound')
  },
  SETTINGS: { 
    path: '/settings',
    exact: true,
    component: load('Settings')
  },
  MYACCOUNT: { 
    path: '/my-account',
    exact: true,
    component: load('MyAccount')
  },
  GLOBALSEARCH: { 
    path: '/search',
    exact: true,
    component: load('GlobalSearchResult')
  },
  PRIVACYPOLICY: { 
    path: '/privacy-policy.html',
    exact: true,
    component: load('PrivacyPolicy')
  },
  TERMS: { 
    path: '/terms.html',
    exact: true,
    component: load('Terms')
  },
  FAQS: { 
    path: '/faqs.html',
    exact: true,
    component: load('Faqs')
  },
  ABOUTUS: { 
    path: '/about-us.html',
    exact: true,
    component: load('AboutUs')
  },
  CONTACTUS: { 
    path: '/contact-us',
    exact: true,
    component: load('ContactUs')
  },
  NOTIFICATIONS: { 
    path: '/notifications',
    exact: true,
    component: load('Notifications')
  },
  CLASSPAYMENTSUCCESS: { 
    path: '/payu/redirect/success',
    exact: true,
    component: load('bookings/PaymentSuccess')
  },
  CLASSPAYMENTFAILURE: { 
    path: '/payu/redirect/failure',
    exact: true,
    component: load('bookings/PaymentFailure')
  },
  START_TEACHING: { 
    path: '/start-teaching',
    exact: true,
    component: load('teacher/TeacherInstituteUser')
  },
  SUBSCRIPTION_WEBINAR_VIDEO: { 
    path: '/subscription/:url/webinar',
    exact: true,
    component: load('teacher/WebinarVideo')
  },
  WEBINAR_VIDEO: { 
    path: '/courses/:url/webinar',
    exact: true,
    component: load('teacher/WebinarVideo')
  },
  CLASS_WEBINAR_VIDEO: {
    path: '/:type/course/:id/webinar',
    exact: true,
    component: load('teacher/WebinarVideo')
  },
  UPDATE_ADDRESS: {
    path: '/checkout',
    exact: true,
    component: load('student/UpdateAddress')
  },
  SIGNUP_LOGIN: {
    path: '/auth',
    exact: true,
    component: load('user/SignupLogin')
  },
  THANK_YOU: {
    path: '/thank-you',
    exact: true,
    component: load('user/ThankYou')
  },
};